@import 'primeicons/primeicons.css';

@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer primeng;

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}
